import Vue from 'vue'
import { Loading } from 'element-ui'
import {advPositionShowList,advDataChoice,advDataUpdate,targetAdvData} from '../api/staticPages'
import router from "../router"
export default {
    namespaced:true,
    state:()=>({
        showList:null,
        total:0,
        medias:null,
        targetAdv:null,
        successful:false,
    }),
    getters:{

    },
    mutations:{
        showListMut(state,arg){
            state.showList = arg.list
            state.total = arg.total
            // console.log(state)
        },
        advChoiceMut(state,arg){
            //console.log(arg.adType)已用于设置广告类型（见Drawer组件）
            state.medias = arg.medias
        },
        targetAdvDataMut(state,arg){
            state.targetAdv = arg
        },
        successMut(state){
            state.successful = !state.successful
        }
    },
    actions:{
        showListAct(context,arg){
            let loadingInstance = Loading.service({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(165, 165, 220, .1)'
            })
            advPositionShowList(arg).then(res=>{
                // console.log(res)
                context.commit('showListMut',res.data.data)
                loadingInstance.close()
            }).catch(err=>{
                loadingInstance.close()
            })
        },
        advChoiceAct(context,arg){
            let loadingInstance = Loading.service({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(165, 165, 220, .1)'
            })
            advDataChoice().then(res=>{
                // console.log(res)
                context.commit('advChoiceMut',res.data.data)
                loadingInstance.close()
            }).catch(err=>{
                loadingInstance.close()
            })
        },
        targetAdvDataAct(context,id){
            let loadingInstance = Loading.service({
                lock: true,
                text: '广告位信息获取...',
                spinner: 'el-icon-loading',
                background: 'rgba(165, 165, 220, .1)'
            })
            targetAdvData({id}).then(res=>{
                // console.log(res)
                context.commit('targetAdvDataMut',res.data.data)
                loadingInstance.close()
            }).catch(err=>{
                loadingInstance.close()
            })
        },
        saveAdvPlaceAct(context,advObj){
            let loadingInstance = Loading.service({
                lock: true,
                text: '正在提交...',
                spinner: 'el-icon-loading',
                background: 'rgba(165, 165, 220, .1)'
            })
            advDataUpdate(advObj).then(res=>{
                if(res.data.status === 0){
                    if(router.history.current.path=='/admin/advposition'){
                        context.dispatch('showListAct')
                    }
                    context.commit('successMut')
                }
                Vue.prototype.$message({
                    showClose: true,
                    message: res.data.message,
                    type: res.data.status === 0 ?'success':'warning'
                })
                loadingInstance.close()
            }).catch(err=>{
                loadingInstance.close()
                console.log(err)
            })
        }
    }
}
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routesFilter'
import defaultSetting from '../setting'
Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next)=>{
  if (to.meta.title) {
		document.title = `${to.meta.title}-${defaultSetting .title}`
	} else {
		document.title = `${defaultSetting .title}`
	}
  if(to.path.match('admin') && !sessionStorage.userInfo){
    return next('/login')
  }
  next()
})

export default router

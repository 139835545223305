import * as echarts from 'echarts/core';
export const lineOption =  {
    color:['#28A5DC','#DCAA28'],
    tooltip: {
      trigger: 'axis',
      axisPointer: { 
        type: 'cross', 
      }
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        axisTick: {
          alignWithLabel: true
        },
        data: ['1月','2月','3月', '4月','5月','6月']
      }
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        position: 'left',
        axisLabel: {
          formatter: '{value}',
        }
      },
      {
        type: 'value',
        min: 0,
        position: 'right',
        axisLabel: {
          formatter: '{value} 元',
        }
      }
    ],
    series: [
      {
        name: '曝光量',
        type: 'line',
        smooth: true,
        showSymbol: false,
        yAxisIndex: 0,
        data: [6, 32, 70, 86, 68.7, 100.7],
      },
      {
        name: '收益金额',
        type: 'line',
        smooth: true,
        showSymbol: false,
        yAxisIndex: 1,
        data: [6.0,10.2,110.3,211.5,210.1,213.2],
      }
    ],
    grid:{
        top:'10%',
        left:'0',
        right:'0',
        bottom:'2%',
        containLabel:true
    }
  }

  export const reportLine =  {
    color:['#28A5DC','#DCAA28'],
    tooltip: {
      trigger: 'axis',
      axisPointer: { 
        type: 'cross', 
      }
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        axisTick: {
          alignWithLabel: true
        },
        data: ['1月','2月','3月', '4月','5月','6月']
      }
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        // max: 150,
        position: 'left',
        axisLabel: {
          formatter: '{value}',
        }
      },
      {
        type: 'value',
        min: 0,
        // max: 250,
        position: 'right',
        axisLabel: {
          formatter: '{value} 元',
        }
      }
    ],
    series: [
      {
        name: '曝光量',
        type: 'line',
        smooth: true,
        showSymbol: false,
        yAxisIndex: 0,
        data: [6, 32, 70, 86, 68.7, 100.7],
      },
      {
        name: '收益金额',
        type: 'line',
        smooth: true,
        showSymbol: false,
        yAxisIndex: 1,
        data: [6.0,10.2,110.3,211.5,210.1,213.2],
      }
    ],
    grid:{
        top:'10%',
        left:'0',
        right:'0',
        bottom:'2%',
        containLabel:true
    }
  }

  export const smlineOption =  {
    color: ['#c1e1eF'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none'
      }
    },
    grid: {
      top:"5%",
      left: '0',
      right: '5%',
      bottom: '0',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        show: false,
      }
    ],
    yAxis: [
      {
        type: 'value',
        show: false,
      }
    ],
    series: [
      {
        name: '千次展示收入',
        type: 'line',
        stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0
        },
        showSymbol: true,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#e1f3fe'
            },
            {
              offset: 1,
              color: '#d1F1FF'
            }
          ])
        },
        emphasis: {
          focus: 'series'
        },
        data: null
      },
    ]
  }
import Home from '../views/Home.vue'
export default [
    {
        path: 'home',
        name: 'Home',
        component: Home,
        iconClass:'el-icon-s-home',
        meta:{
            title:'首页'
        }
    },
    // {
    //     title:'流量合作',
    //     iconClass:'el-icon-location',
    //     meta:{
    //         title:'流量合作'
    //     },
    //     childRoutes:[
    //         {
    //             path:'media',
    //             name:'Media',
    //             component:() => import('../views/Media'),
    //             meta:{
    //                 title:'我的媒体'
    //             },
    //         },
    //         {
    //             path:'advposition',
    //             name:'Advposition',
    //             component:() => import('../views/AdvPosition'),
    //             meta:{
    //                 title:'我的广告位'
    //             },
    //         }
    //     ]
    // },
    {
        path: 'datareport',
        name: 'Datareport',
        component: () => import('../views/DataReport'),
        iconClass:'el-icon-menu',
        meta:{
            title:'数据报表'
        }
    },
    {
        title:'结算管理',
        iconClass:'el-icon-location',
        meta:{
            title:'结算管理'
        },
        childRoutes:[
            {
                path:'financialinfo',
                name:'Financialinfo',
                component:() => import('../views/FinancialInfo'),
                meta:{
                    title:'财务信息'
                },
            },
            {
                path:'brokerage',
                name:'Brokerage',
                component:() => import('../views/Brokerage'),
                meta:{
                    title:'分佣收益'
                },
            }
        ]
    },
    {
        path: 'msgcenter',
        name: 'Msgcenter',
        component: () => import('../views/MsgCenter'),
        meta:{
            title:'消息中心'
        },
        iconClass:'el-icon-setting',
    }
]
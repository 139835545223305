import instance from "../utils/request";

// 首页
export const homeData = ()=>{
    return instance.post('/admin/index')
}

//媒体合作
export const mediaShowList = (arg)=>{
    return instance.post('/media/index',arg)
} 
export const mediaData = ()=>{
    return instance.post('/media/choic')
} 
export const targetMediaInfo = (id)=>{
    return instance.post('/media/info',id)
}
export const mediaUpdate = (arg)=>{
    return instance.post('/media/store',arg)
}

//广告位
export const advPositionShowList = arg=>instance.post('/advplace/index',arg)

export const advDataChoice = ()=>instance.post('/advplace/choic')

export const advDataUpdate = arg=>instance.post('advplace/store',arg)

export const targetAdvData = arg=>instance.post('/advplace/info',arg)

//数据报表
export const reportDataOptions = ()=>{//数据选项
    return instance.post('/report/chioc')
}
export const reportEchartData = (arg)=>{
    return instance.post('/report/getEchartsData',arg)
}
export const reportData = (arg)=>{
    return instance.post('/report/index',arg)
}

//财务信息
export const getFinancialData = ()=>{
    return instance.post('/financial/index')
}
export const FinancialStore = (arg)=>{
    return instance.post('/financial/store',arg)
}

// 分佣收益
export const getBrokerageData = (arg)=>{
    return instance.post('/settlement/index',arg)
}
//消息中心
export const reqMsg = (arg)=>{
    return instance.post('/message/index',arg)
}
<template>
    <div class="homeParent" v-if='homeDataObj'>
        <div class="showUser">
            <div class="userinfo">
                <p class="margin-tb-sm">Hi, {{username}}</p>
                <p>邮箱：{{useremail}}</p>
            </div>
        </div>
        <div class="income">
            <div class="incomeTitle">
                 <Help title="预估收入" ><p>您到目前为止累积的收入，以北京时间为准。这笔金额是预估值，<br/>可能会在每个月初确认您的收入时进行调整，以确保准确性。</p></Help> <!-- 帮助内容写在标签中间（插槽）-->
                <p>最新数据：{{homeDataObj.lastDate}}</p>
            </div>
            <div class="incomeDetail">
                <div>
                    <span>昨日收入</span>
                    <b><span>{{homeDataObj.historyData.yesterday}}</span> 元</b>
                    <p><ShowRate :rateData='homeDataObj.historyData.yesterday_p' /></p>
                </div>
                <div>
                    <span>过去7日收入</span>
                    <b><span>{{homeDataObj.historyData.seven}}</span> 元</b>
                    <p><ShowRate :rateData='homeDataObj.historyData.seven_p' /></p>
                </div>
                <div>
                    <span>本月累计收入</span>
                    <b><span>{{homeDataObj.historyData.month}}</span> 元</b>
                </div>
                <div>
                    <span>本年累计收入</span>
                    <b><span>{{homeDataObj.historyData.year}}</span> 元</b>
                </div>
                <div>
                    <span>总收入</span>
                    <b><span>{{homeDataObj.historyData.total}}</span> 元</b>
                </div>
            </div>
        </div>
        <div class="chart">
            <div class="showIncome">
                <Help title="千次展示收入"><span class="makeClear">预估收入 / 曝光量 * 1000</span></Help>
                <div class="showIncomeDetail">
                    <span>千次展示收入</span>
                    <div>
                        <b><span>{{homeDataObj.thousands.money}}</span> 元</b>
                        <ShowRate :rateData='homeDataObj.thousands.p' />
                    </div>
                </div>
                <div class="showIncomeChart">
                    <Echart :option="smlineOption" class="miniChart"/>
                </div>
            </div>
            <div class="flowFunnel">
                <Help title="流量漏斗" class="helpDiv">
                    <div class="funnelRedu">
                        <p>专用指标说明</p>  
                        <p><span>广告位请求量</span>扬歌从您的应用收到的广告位请求数</p>
                        <p><span>广告位返回量</span>系统对应用发出的广告位请求做出响应而返回广告的个数</p>
                        <p><span>曝光量</span>扬歌向应用的用户展示广告的次数</p>
                        <p><span>点击量</span>用户点击广告的次数</p>
                        <p><span>广告位填充率</span>广告位返回量 / 广告位请求量 * 100%</p>
                        <p><span>广告位曝光率</span>曝光量 / 广告位返回量 * 100%</p>
                        <p><span>点击率</span>点击量 / 曝光量 * 100%</p>
                    </div>
                </Help>
                <DiyFunnel class="diychart" :resData="homeDataObj.flowFunnel"/>
            </div>
        </div>
        <div class="allLineChart">
            <div class="chartTitle">数据趋势</div>
            <div class="chartCheck">
                <!-- 必须给两个Id配置点击区域外隐藏option -->
                <CheckOneOption 
                    selectId="homeSelectOne" 
                    optionId="homeOptionOne" 
                    :lineShowList="['曝光量']"
                />
                <CheckOneOption 
                    selectId="homeSelectTwo" 
                    optionId="homeOptionTwo" 
                    checkedValue="预估收入" 
                    mackColor="#DCAA28"
                    :lineShowList="['预估收入']"
                />
            </div>
            <div class="chartBox">
                <Echart :option="lineOption"/>
            </div>
        </div>
    </div>
</template>

<script>
    import DiyFunnel from "../components/DiyFunnel.vue"
    import Help from "../components/Help.vue"
    import CheckOneOption from "../components/CheckOneOption.vue"
    import Echart from "../components/Echart.vue"
    import inputDateCheck from "../components/InputDateCheck.vue"
    import ShowRate from "../components/ShowRate.vue"
    import  {mapGetters} from 'vuex'
    export default {
        components:{
            DiyFunnel,Help,Echart,inputDateCheck,CheckOneOption,ShowRate
        },
        data:()=>({

        }),
        computed: {
            ...mapGetters({
                username:"user/username",
                useremail:"user/useremail",
                smlineOption:"home/newSmlineOption",
                lineOption:"home/newLineOption"
            }),
            homeDataObj(){
                return  this.$store.state.home.homeDataObj
            }
        },
        created(){
           
        },
        methods: {
            
        },
    }
</script>

<style lang="less" scoped>
    .flexDisplay(){
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .homeParent{
        background: #F5F7F9;
        padding-bottom: 10px;
    }
    .showUser{
        width:100%;
        height:108px;
        padding:38px;
        box-sizing: border-box;
        background: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .userinfo{
            p:first-child{
                font-size: 26px;
                font-weight: 600;
            }
            p:last-child{
                font-size: 14px;
                color: #94969C;
            }
        }
    }
    .income{
        margin:25px;
        height:148px;
        background: white;
        border-radius: 4px;
        padding: 20px;
        .incomeTitle{
            .flexDisplay();
            .parent p{
                font-size: 14px;
                font-weight: normal;
                white-space: nowrap;
            }
            >p:last-child{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #BDBFC1;
                line-height: 20px; 
            }
        }
        .incomeDetail{
            .flexDisplay();
            margin-top:20px;
            div{
                display: flex;
                flex-direction: column;
                border-right:1px solid #EAEAEA;
                width:18%;
                &:last-child{
                   border-right:none; 
                }
                span{
                    font-size: 14px;
                    color: #8F9196;
                    line-height: 20px;
                }
                b{
                    font-size: 14px;
                    span{
                        font-size: 24px;
                        font-weight: 600;
                        color: #333333;
                        line-height:45px
                    }
                }
            }
        }
    }
    .chart{
        display: flex;
        justify-content: space-between;
        margin:25px;
        height:380px;
        .showIncome{
            height: 100%;
            background: white;
            width:33%;
            padding:20px;
            border-radius: 5px;
            box-sizing: border-box;
            .makeClear{
                font-size: 14px;
                font-weight: normal;
                white-space: nowrap;
            }
            .showIncomeDetail{
                margin:20px;
                display: flex;
                flex-direction: column;
                span{
                    font-size: 14px;
                    color: #8F9196;
                    line-height: 20px;
                }
                b{
                    font-size: 14px;
                    margin-right: 14px;
                    span{
                        font-size: 24px;
                        font-weight: 500;
                        color: #333333;
                        line-height:30px
                    }
                }
            }
            .showIncomeChart{
                height: 230px;
                position: relative;
                .miniChart{
                    margin:0 auto;
                }
            }
        }
        .flowFunnel{
            height: 100%;
            background: white;
            width:65%;
            border-radius: 5px;
            padding: 20px;
            padding-bottom:30px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .helpDiv{
                width:100%;
            }
            .funnelRedu{
                font-weight: normal;
                white-space: nowrap;
                font-size: 14px;
                p{
                    padding: 6px 0;
                    color: #777;
                    span{
                        display: inline-block;
                        width: 120px;
                        text-align: right;
                        color: #333;
                        margin-right: 12px;
                    }
                }
                p:first-child{
                    border-bottom: .3px solid #eef;
                    padding-bottom: 16px;
                    margin-bottom: 12px;
                    font-weight: 600;
                    color: #333;
                }
            }
        }
    }
    .allLineChart{
        margin:25px;
        padding:20px 20px 30px;
        padding-top:30px;
        box-sizing: border-box;
        height: 460px;
        background: white;
        .chartTitle{
            font-size: 16px;
            font-weight: 900;
        }
        .chartCheck{
            display: flex;
            justify-content: flex-start;
            margin-top:16px;
            
        }
        .chartBox{
            height:320px;
            margin-right:40px;
        }
    }
</style>
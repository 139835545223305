import Vue from 'vue'
import { Loading } from 'element-ui'
import {mediaShowList,mediaData,targetMediaInfo,mediaUpdate} from '../api/staticPages'
export default {
    namespaced:true,
    state:()=>({
        mediaShowList:null,
        total:0,
        industries:null,
        targetMediaInfo:null,
        successful:false
    }),
    getters:{
       
    },
    mutations:{
        getMediaShowListMut(state,arg){
            state.mediaShowList = arg.list
            state.total = arg.total
            // console.log(arg.list)
        },
        getMediaChoiceMut(state,arg){
            let arr = []
            for(let i in arg){//这里变换一下后端传回的数据格式
                let obj = {}
                obj.value = arg[i].id
                obj.label = arg[i].name
                obj.children = arg[i].child
                obj.children.forEach(item=>{
                    item.value = item.id
                    item.label = item.name
                    delete item.id
                    delete item.name
                })
                arr.push(obj)
            }
            state.industries = arr
        },
        targetMediaInfoMut(state,arg){
            state.targetMediaInfo = arg
        },
        successMut(state){
            state.successful = !state.successful
        }

    },
    actions:{
        getMediaShowListAct(context,arg){
            
            let loadingInstance = Loading.service({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(165, 165, 220, .1)'
            })
            mediaShowList(arg).then(res=>{
                if(res.data.status === 0){
                    context.commit('getMediaShowListMut',res.data.data)
                }
                loadingInstance.close()
            }).catch(err=>{
                loadingInstance.close()
            })
        },
        getMediaChoiceAct(context){
            mediaData().then(res=>{
                if(res.data.status === 0){
                    context.commit('getMediaChoiceMut',res.data.data.industries)
                }
            })
        },
        targetMediaInfoAct(context,id){
            let loadingInstance = Loading.service({
                lock: true,
                text: '获取媒体数据···',
                spinner: 'el-icon-loading',
                background: 'rgba(165, 165, 220, .1)'
            })
            targetMediaInfo({id}).then(res=>{
                console.log(res)
                context.commit('targetMediaInfoMut',res.data.data)
                loadingInstance.close()
            }).catch(err=>{
                loadingInstance.close()
            })
        },
        mediaUpdateAct(context,arg){
            console.log(arg)
            let loadingInstance = Loading.service({
                lock: true,
                text: '获取媒体数据···',
                spinner: 'el-icon-loading',
                background: 'rgba(165, 165, 220, .1)'
            })
            mediaUpdate(arg).then(res=>{
                console.log(res)
                if(res.data.status === 0){
                    context.dispatch('getMediaShowListAct')
                    context.commit('successMut')
                }
                Vue.prototype.$message({
                    showClose: true,
                    message: res.data.message,
                    type: res.data.status === 0 ?'success':'warning'
                })
                loadingInstance.close()
            }).catch(err=>{
                loadingInstance.close()
            })
        }
    }
}